import React from 'react'
import styled from 'styled-components'
import Logo from './logo'
import MediaQueries from './media-queries'
import '@fontsource/noto-sans-jp'

const HeaderContainer = styled.header`
  background-color: #1d1e21;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  
  ${MediaQueries.greaterThan("tablet")`
    justify-content: space-between;
    padding: 0 3rem;
  `};
`

const Title = styled.h1`
  margin: 0;
  height: 75px;
  display: flex;
`

const Box = styled.div`
  color: #fff;
  border: 1px solid #fff;
  font-size: 0.675rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.25rem;
  font-family: "Noto Sans JP", sans-serif;
  
  ${MediaQueries.greaterThan('tablet')`
    font-size: 0.875rem; 
  `};
`

const text = '特許・意匠出願中'

const Header = () => {
  return (
    <HeaderContainer>
      <Title>
        <Logo />
      </Title>
      <Box>
        {text}
      </Box>
    </HeaderContainer>
  )
}

export default Header