import React, { useState } from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import MediaQueries from './media-queries'

const Section = styled.section`
  background-color: #232129;
  color: #fff;
  text-align: center;
  padding: 3rem 2rem;
  margin-top: 5rem;

  ${MediaQueries.greaterThan('tablet')`
     padding: 3rem 0; 
    margin-top: 6rem;
  `};
`

const Title = styled.h3`
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.06rem;

  ${MediaQueries.greaterThan('tablet')`
    font-size: 1.2rem;
  `};
`

const Input = styled.input`
  appearance: none;
  display: block;
  padding: 1rem;
  margin: 2rem auto 0;
  border: none;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  width: 100%;

  ${MediaQueries.greaterThan('tablet')`
    width: 20rem;
  `};
`

const SubmitButton = styled.div`
  button {
    appearance: none;
    margin-top: 2rem;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    background: #fff;

    &:hover {
      cursor: pointer;
    }

    ${MediaQueries.greaterThan('tablet')`
    padding: 0.6rem 2rem;
  `}; 
  }
`

const Message = styled.p`
  line-height: 2rem;
  margin-top: 2rem;
  font-size: 0.875rem;

  ${MediaQueries.greaterThan('tablet')`
    font-size: 1rem;
  `};
`

const Form = () => {
  const [ email, setEmail ] = useState('')
  const [ submitted, setSubmitted ] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    addToMailchimp(email, {
      'group[71972][1]': '1',
    })
      .then((data) => {
        setEmail('')
        setSubmitted(true)
      })
      .catch((err) => {
        alert('エラーが発生しました。メールアドレスが正しく入力されているか確認をして、もう一度操作を行なってください。')
      })
  }

  const handleEmailChange = (e) => {
    setEmail(e.currentTarget.value)
  }

  const html = `
    <button type="submit" onsubmit="return gtag_report_conversion('https://zeta.exarm.com')">メルマガを登録する</button>
  `

  return (
    <Section>
      <form onSubmit={handleSubmit}>
        <Title>製品に関する情報をお送りします</Title>
        <div>
          <Input onChange={handleEmailChange} value={email} placeholder="メールアドレス" name="email" type="text" required />
          <SubmitButton dangerouslySetInnerHTML={{ __html: html }}></SubmitButton>
        </div>
      </form>
      {submitted ? (
        <Message>
          ご登録いただきありがとうございます。<br />EXARM ZETAの最新情報をお届けいたします。
        </Message>
      ) : ''}
    </Section>
  )
}

export default Form