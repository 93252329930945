import React from 'react'
import styled from 'styled-components'
import '@fontsource/lato/700-italic.css'
import MediaQueries from './media-queries'

const HeadingContainer = styled.div`
  font-size: 1rem;
  background-color: #1d1e21;
  padding: 1.5rem 2rem 1.5rem 2rem;
  color: #fff;
  
  ${MediaQueries.greaterThan('tablet')`
    padding: 2rem 3rem 2rem 3rem;
  `};
`

const Title = styled.h2`
  font-size: 2rem;
  letter-spacing: 0.1rem;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-style: italic;
  
  ${MediaQueries.greaterThan('tablet')`
    font-size: 3rem;
  `};
`

const Content = styled.p`
  font-size: 1rem;
  margin-top: 1rem;
  letter-spacing: 0.06rem;
  line-height: 2rem;
  
  ${MediaQueries.greaterThan('tablet')`
    font-size: 1rem;
  `};
`

const Heading = ({ title, content }) => {
  return (
    <HeadingContainer>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </HeadingContainer>
  )
}

export default Heading