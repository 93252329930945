import React from 'react'
import styled from 'styled-components'
import MediaQueries from '../media-queries'
import { StaticImage } from 'gatsby-plugin-image'

const Section = styled.section`

`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  
  ${MediaQueries.greaterThan('tablet')`
    flex-direction: row
  `};
`

const Col = styled.div`
  &:first-child {
    flex-basis: 100%;
    min-width: 100%;
    max-width: 100%;
    
    ${MediaQueries.greaterThan('tablet')`
      flex-basis: 33%;
      min-width: 33%;
      max-width:33%;
    `};
  }
  
  &:last-child {
    flex-basis: 100%;
    min-width: 100%;
    max-width: 100%;
    
    ${MediaQueries.greaterThan('tablet')`
      padding-left: 2rem;
      flex-basis: 67%;
      min-width: 67%;
      max-width:67%;
    `};
  }
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 1.2rem;
  margin: 2rem 0 0;
  
  ${MediaQueries.greaterThan('tablet')`
    margin: 0;
  `};
`

const Content = styled.p`
  line-height: 1.8rem;
`

const title = 'フォトグラファー zuma代表 武居 厚志'
const content = '色に正確性を求められる仕事柄、撮影商品やプリントをモニターで色確認をする場合、明るさにムラがないのがありがたい。しかも、モニターに光が当たらない設計なので、モニターの色ムラも防げる。パソコン周りは何かと手狭になりがちだが、モニターの上に取り付けるだけと、ライトを取り付けるスペースが全くいらないのが嬉しい。'

const Voice02 = () => {
  return (
    <Section>
      <Row>
        <Col>
          <StaticImage src="../../images/thumb-voice-02.jpg"
                       alt="EXARM ZETA 目に優しい。均一な光"
                       formats={[ 'auto', 'webp', 'avif' ]}
                       layout="fullWidth"
                       aspectRatio={200 / 261}
                       placeholder="blurred"
          />
        </Col>
        <Col>
          <Title>{title}</Title>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </Col>

      </Row>
    </Section>
  )
}

export default Voice02