import React from 'react'
import styled from 'styled-components'
import Logo from './logo'
import '@fontsource/lato'
import MediaQueries from './media-queries'

const Container = styled.footer`
  background-color: #1d1e21; 
  padding: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-family: 'Lato', sans-serif;
  flex-direction: column;
  
  ${MediaQueries.greaterThan('tablet')`
    flex-direction: row;
    padding: 2rem 3rem;
  `};
`

const Copyright = styled.p`
  font-size: 0.675rem;
  margin-top: 2rem;
  font-weight: 200;
  letter-spacing: 0.06rem;
`

const Footer = () => {
  return (
    <Container>
      <Logo />
      <Copyright>&copy; 2021 SWAN Lite Manufacturing Co., LTD.</Copyright>
    </Container>
  )
}

export default Footer