import React from 'react'
import styled from 'styled-components'
import '@fontsource/lato/300.css'
import StoryItem from './story-item'
import StoryBox from './story-box'
import MediaQueries from './media-queries'

const Section = styled.section`
  background-color: #fff;
  color: #000;
  margin: 0 auto;
  padding: 2rem;

  ${MediaQueries.greaterThan('desktop')`
    padding: 4rem 0;
    max-width: 800px;
  `};
`

const Title = styled.h2`
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  margin: 0;
`

const Story = () => {
  return (
    <Section>
      <Title>STORY</Title>
      <StoryItem title="昼はビジネスマン、夜はゲーマー" content="目に優しい機能は大前提として必要。プレーを演出する為のイルミネーション機能やリモートワークでも使えるフェイスライトも搭載。さらにタッチレスセンサー機能をつけスマートでスタイリッシュなデザインに仕上げました。" />
      <StoryItem title="国内生産へのこだわり" content="感染症の影響で小さな町工場は廃業も余儀なくされております。熟練した職人さん達も厳しい状況におかれています。私達のような国内生産を主体とするメーカーは町工場がなくなってしまうと二度と国内で作ることが出来なくなってしまうのが今の日本です。日本製を未来へつなぐ役割を私達は担っていると考えてます。" />
      <StoryItem title="熟練した職人" content="多くの部品は国内の町工場で製造されています。プラスチックを作る金型設計、スプリング専門メーカー、金属加工、塗装などその一つ一つの工程に熟練した職人さん達の経験が詰まっています。EXARM ZETAは熟練した職人さん達の技と技が重なり合って出来上がっている商品です。" />
      <StoryBox />
    </Section>
  )
}

export default Story