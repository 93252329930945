import React from 'react'
import styled from 'styled-components'

const Box = styled.div`
  line-height: 2rem;
  letter-spacing: 0.06rem;
  border: 1px solid #231815;
  padding: 1.5rem 2rem;
  margin-top: 4rem;
  border-radius: 0.25rem;
`

const StoryBox = () => {
  return (
    <Box>
      開発者がゲーム好き<br />
      夜9時から始めたゲームが気が付いたら朝まで、長時間プレイしていると目への負担がひどい事になっていた。EXARMZETAはそんな実体験をもとに”目に優しいライト”があれば快適な環境でプレイできるのではないかとの思いから開発を始めました。
    </Box>
  )
}

export default StoryBox