import React from 'react'
import styled from 'styled-components'
import MediaQueries from './media-queries'

const Section = styled.section`
  margin: 2rem auto 0;
  padding: 0 2rem;
  ${MediaQueries.greaterThan('tablet')`
    max-width: 800px;  
    margin: 4rem auto 0;
  `};
`

const Link = styled.a`
  background: #000;
  color: #fff;
  text-decoration: none;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  letter-spacing: 0.1em;
  margin: 2rem auto;
  display: block;
  text-align: center;
  width: 280px;
  ${MediaQueries.greaterThan('tablet')`
    font-size: 1.2rem;
    width: 350px;
  `};
`

const Official = () => {
  const official = {
    text: 'EXARM公式での詳細はこちら',
    url: 'https://exarm.swanlighting.jp/series/exarm-zeta/'
  }

  return (
    <Section>
      <Link href={official.url} target="_blank" rel="noreferrer noopener">
        {official.text}
      </Link>
    </Section>
  )
}

export default Official