import React from 'react'
import styled from 'styled-components'
import MediaQueries from './media-queries'

const Section = styled.section`
  margin: 4rem auto 0;
  padding: 0 2rem;
  
  ${MediaQueries.greaterThan('desktop')`
    margin: 8rem auto 0;
    max-width: 1200px;
    padding: 0;
  `};
`

const Container = styled.div`
  position: relative;
  padding-bottom: 56.25%; /*アスペクト比 16:9の場合の縦幅*/
  height: 0;
  overflow: hidden;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Movie = () => {
  return (
    <Section>
      <Container>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/x1Zw707r8Ao" title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
      </Container>
    </Section>
  )
}

export default Movie