import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import MediaQueries from './media-queries'

const Section = styled.section`
  margin: 4rem auto 0;
  padding: 0 2rem;
  ${MediaQueries.greaterThan('tablet')`
    max-width: 800px;  
    margin: 8rem auto 0;
  `};
`

const Title = styled.h3`
  margin: 2rem auto 0;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.5rem;
  font-size: 1.1rem;

  ${MediaQueries.greaterThan('tablet')`
    margin: 4rem auto 0;
    font-size: 1.8rem;
  `};
`

const Content = styled.p`
  text-align: center;
  line-height: 2rem;
  margin-top: 2rem;
  font-size: 0.875rem;

  ${MediaQueries.greaterThan('tablet')`
    font-size: 1rem; 
  `};

  br.v-sp {
    ${MediaQueries.greaterThan('tablet')`
      display: none;
    `};
  }
`

const Link = styled.a`
  background: #ffba00;
  color: #fff;
  text-decoration: none;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  letter-spacing: 0.1em;
  margin: 2rem auto;
  display: block;
  text-align: center;
  width: 200px;
  ${MediaQueries.greaterThan('tablet')`
    font-size: 1.2rem;
    width: 290px;
  `};
`

const Makuake = () => {
  const heading = '1238%の達成率で<br />Makuakeプロジェクト終了致しました。'
  const contents = '元プロゲーマー推奨e-スポーツに最適。<br />照明のプロが作った目に優しいゲーミングライト<br class="v-sp" />EXARM ZETA'
  const makuake = {
    text: 'プロジェクト詳細はこちら',
    url: 'https://www.makuake.com/project/exarm_zeta/'
  }

  return (
    <Section>
      <StaticImage src="../images/makuake-exarm-zeta.png"
                   alt="Makuake x EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                   layout="fullWidth"
                   aspectRatio={2000 / 135}
                   placeholder="blurred"
      />
      <Title dangerouslySetInnerHTML={{ __html: heading }} />
      <Content dangerouslySetInnerHTML={{ __html: contents }} />
      <Link href={makuake.url} target="_blank" rel="noreferrer noopener">
        {makuake.text}
      </Link>
    </Section>
  )
}

export default Makuake