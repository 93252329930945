import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import MediaQueries from './media-queries'
import rainbowGIF from '../images/exarm-zeta-colors.gif'
import rotationGIF from '../images/rotate-zeta.gif'
import rangeMotionGIF from '../images/range-motion-zeta.gif'
import colorsGIF from '../images/colors-zeta.gif'

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  background-color: #fff;
  gap: 2rem;
  padding: 4rem 2rem;
  max-width: 100%;
  
  ${MediaQueries.greaterThan('tablet')`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    max-width: 1200px;
    margin: 0 auto;
    padding: 8rem 2rem 6rem;
  `};
  
  ${MediaQueries.greaterThan('desktop')`
    padding: 8rem 0 6rem;
  `};
`

const Col = styled.div`
  position: relative;
  max-width: 100%;
  
  img {
    max-width: 100%;
  }
`

const ConceptImages = () => {
  return (
    <Container>
      <Col>
        <StaticImage src="../images/exarm-zeta-black.jpg"
                     alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                     layout="fullWidth"
                     aspectRatio={4134 / 2756}
                     placeholder="blurred"
        />
      </Col>
      <Col>
        <StaticImage src="../images/exarm-zeta-metallic-silver.jpg"
                     alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                     layout="fullWidth"
                     aspectRatio={4134 / 2756}
                     placeholder="blurred"
        />
      </Col>
      <Col>
        <StaticImage src="../images/exarm-zeta-white.jpg"
                     alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                     layout="fullWidth"
                     aspectRatio={4134 / 2756}
                     placeholder="blurred"
        />
      </Col>
      <Col>
        <StaticImage src="../images/exarm-zeta-mounted.jpg"
                     alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                     layout="fullWidth"
                     aspectRatio={924 / 738}
                     placeholder="blurred"
        />
      </Col>
      <Col>
        <StaticImage src="../images/exarm-zeta-front.jpg"
                     alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                     layout="fullWidth"
                     aspectRatio={1000 / 677}
                     placeholder="blurred"
        />
      </Col>
      <Col>
        <StaticImage src="../images/exarm-zeta-back.jpg"
                     alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                     layout="fullWidth"
                     aspectRatio={1000 / 677}
                     placeholder="blurred"
        />
      </Col>
      <Col>
        <StaticImage src="../images/exarm-zeta-up.jpg"
                     alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                     layout="fullWidth"
                     aspectRatio={1000 / 667}
                     placeholder="blurred"
        />
      </Col>
      <Col>
        <StaticImage src="../images/exarm-zeta-side.jpg"
                     alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                     layout="fullWidth"
                     aspectRatio={1000 / 667}
                     placeholder="blurred"
        />
      </Col>
      <Col>
        <img src={rotationGIF} alt="EXARM ZETA" width={`320`} />
      </Col>
      <Col>
        <img src={rainbowGIF} alt="EXARM ZETA" width={`320`} />
      </Col>
      <Col>
        <img src={rangeMotionGIF} alt="EXARM ZETA" width={`320`} />
      </Col>
      <Col>
        <img src={colorsGIF} alt="EXARM ZETA" width={`320`} />
      </Col>
    </Container>
  )
}

export default ConceptImages