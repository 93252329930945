import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Heading from './heading'

const Game = () => {
  return (
    <section>
      <StaticImage src="../images/game.jpg"
                   alt="EXARM ZETA"
                   formats={[ 'auto', 'webp', 'avif' ]}
                   layout="fullWidth"
                   aspectRatio={2000 / 1136}
                   placeholder="blurred"
      />
      <Heading title="for GAME(e-sports)" content="ゲームのプロも推奨!照明のプロが作った目に優しい日本製ゲーミングライト‼︎" />
    </section>
  )
}

export default Game