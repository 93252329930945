import React from 'react'
import styled from 'styled-components'
import MediaQueries from './media-queries'

const Section = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  background: #eee;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 1rem 0;
  
  ${MediaQueries.greaterThan('tablet')`
    flex-direction: row;
    padding: 0;
  `};
`

const Text = styled.div`
  font-size: 0.85rem;
  ${MediaQueries.greaterThan('tablet')`
    font-size: 1.4rem;
  `};
`

const Button = styled.a`
  background: #ffba00;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem; 
  border-radius: 0.5rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  letter-spacing: 0.1em;
  margin: 0.5rem 0 0 0;
  ${MediaQueries.greaterThan('tablet')`
    font-size: 1.2rem;
    padding: 1rem 2rem; 
    margin: 0 0 0 2rem;
  `};
`

const text = 'Makuakeにてプロジェクト公開中！(8/6まで)'

const linkText = '応援購入はこちら'

const CallToAction = () => {
  return (
    <Section>
      <Text>{text}</Text>
      <Button href="https://www.makuake.com/project/exarm_zeta/" target="_blank" rel="noopener">
        {linkText}
      </Button>
    </Section>
  )
}

export default CallToAction