import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Heading from './heading'

const DeskWork = () => {
  return (
    <section>
      <StaticImage src="../images/desk-work.jpg"
                   alt="EXARM ZETA"
                   formats={[ 'auto', 'webp', 'avif' ]}
                   layout="fullWidth"
                   aspectRatio={2000 / 1136}
                   placeholder="blurred"
      />
      <Heading title="for DESK WORK" content="デスク回りスッキリ、目に優しい手元を照らす確かな光。取り付け簡単モニターライト。" />
    </section>
  )
}

export default DeskWork