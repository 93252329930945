import React from 'react'
import styled from 'styled-components'
import Voice01 from './voices/voice01'
import Voice02 from './voices/voice02'
import Voice03 from './voices/voice03'
import MediaQueries from './media-queries'
import { Link } from 'gatsby'

const Section = styled.section`
  max-width: 1200px;
  margin: 6rem auto 0;
  padding: 0 2rem;
  ${MediaQueries.greaterThan('tablet')`
    margin: 10rem auto 0;
  `};
`

const Row = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: start;
  
  ${MediaQueries.greaterThan('tablet')`
    flex-direction: row;
    align-items: start;
    gap: 4rem;
  `};
`

const Date = styled.div`
  width: 5rem;
`

const Title = styled.h2`
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  margin: 0 0 3rem 0; 
`

const NewsTitle = styled.h2`
  font-size: 1rem;
  color: black;
  font-weight: 700;
`

const title = "Media"

const items = [
  {
    date: '2022/06/25',
    media: 'ミニメモ!',
    title: 'これがメイドインジャパンのモニターライトだ！「EXARM ZETA」がゲームにも作業にも最適すぎた',
    url: 'https://minimemolog.com/exarm-zeta/',
  },
  {
    date: '2022/06/20',
    media: 'ガジェットランナー',
    title: '【EXARM ZETA レビュー】明るすぎない光とイルミネーションが特徴のゲーミングモニターライト',
    url: 'https://hashikan.net/archives/exarm-zeta.html',
  },
]

const Blog = () => {
  return (
    <Section>
      <Title>{title}</Title>
      {items.map((item, index) =>(
        <Row>
          <Date>{item.date}</Date>
          <Link to={item.url} target="_blank" rel="noreferrer noopener" style={{textDecoration: 'none'}}>
            <div style={{color: 'black'}}>「{item.media}」に掲載されました。</div>
            <NewsTitle>「{item.title}」</NewsTitle>
          </Link>
        </Row>
      ))}
      <Row>
        <Date>2022.08.10</Date>
        <div style={{width: '100%'}}>
          <div style={{color: 'black'}}>「ドランクドラゴンのバカ売れ研究所」で紹介されました。</div>
          <div style={{marginTop: '2rem'}}>
            <div style={{width:'100%', aspectRatio: '16 / 9'}}>
              <iframe src="https://www.youtube.com/embed/dZuIsJX67cY"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      width="100%"
                      height="100%"
              ></iframe>
            </div>

          </div>

        </div>
      </Row>
    </Section>
  )
}

export default Blog