import React from 'react'
import styled from 'styled-components'

const Article = styled.article`
  letter-spacing: 0.06rem;
`

const Title = styled.h3`
  font-size: 1.1rem;
  margin-top: 3rem;
`

const Content = styled.p`
  line-height: 2rem;
`

const StoryItem = ({title, content}) => {
  return (
    <Article>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Article>
  )
}

export default StoryItem