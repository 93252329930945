import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import MediaQueries from './media-queries'

const Section = styled.section`
  margin: 2rem auto 0;
  ${MediaQueries.greaterThan('tablet')`
    margin: 10rem auto 0;
  `};
`

const Row = styled.div`
  grid-template-columns: repeat(1, minmax(0, 1fr));
  display: grid;
  margin: 0 auto;
  padding: 4rem 2rem 4rem;
  ${MediaQueries.greaterThan('tablet')`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    max-width: 1200px;
  `};
`

const Col = styled.div`
`

const Link = styled.a`
  background: #000;
  color: #fff;
  text-decoration: none;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  letter-spacing: 0.1em;
  margin: 2rem auto;
  display: block;
  text-align: center;
  width: 290px;
  ${MediaQueries.greaterThan('tablet')`
    font-size: 1rem;
    width: 290px;
  `};
`

const Color = styled.h3`
  text-align: center;
  font-size: 1.2rem;
`

const Title = styled.h2`
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  margin: 0;
`

const storeURL = 'https://swanlighting.stores.jp/items/611f70c3e49433440e3ee589'

export default function Store() {
  const blackURL = 'https://www.amazon.co.jp/dp/B09DPP6Z26?ref=myi_title_dp'
  const metallicSilverURL = 'https://www.amazon.co.jp/dp/B09DQ5Y8V1?ref=myi_title_dp'
  const whiteURL = 'https://www.amazon.co.jp/dp/B09DQ3Q9SX?ref=myi_title_dp'
  const amazonCartText = 'Amazonでのご購入はこちら'
  const storeCartText = '公式ストアでのご購入はこちら'

  return (
    <Section>
      <Title>STORE</Title>
      <Row>
        <Col>
          <div>
            <StaticImage
              src="../images/exarm-zeta-black.jpg"
              alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
              layout="fullWidth"
              aspectRatio={4134 / 2756}
              placeholder="blurred"
            />
          </div>
          <Color>ブラック</Color>
          <Link href={blackURL} target="_blank" rel="noreferrer noopener">
            {amazonCartText}
          </Link>
          <Link href={storeURL} target="_blank" rel="noreferrer noopener">
            {storeCartText}
          </Link>
        </Col>
        <Col>
          <div>
            <StaticImage
              src="../images/exarm-zeta-metallic-silver.jpg"
              alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
              layout="fullWidth"
              aspectRatio={4134 / 2756}
              placeholder="blurred"
            />
          </div>
          <Color>メタリックシルバー</Color>
          <Link href={metallicSilverURL} target="_blank" rel="noreferrer noopener">
            {amazonCartText}
          </Link>
          <Link href={storeURL} target="_blank" rel="noreferrer noopener">
            {storeCartText}
          </Link>
        </Col>
        <Col>
          <div>
            <StaticImage
              src="../images/exarm-zeta-white.jpg"
              alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
              layout="fullWidth"
              aspectRatio={4134 / 2756}
              placeholder="blurred"
            />
          </div>
          <Color>ホワイト</Color>
          <Link href={whiteURL} target="_blank" rel="noreferrer noopener">
            {amazonCartText}
          </Link>
          <Link href={storeURL} target="_blank" rel="noreferrer noopener">
            {storeCartText}
          </Link>
        </Col>
      </Row>
    </Section>
  )
}