import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

const Container = styled.section`
`

const MadeInJapan = () => {
  return (
    <Container>
      <StaticImage src="../images/exarm-zeta-made-in-japan.jpg"
                   alt="EXARM ZETA"
                   formats={[ 'auto', 'webp', 'avif' ]}
                   layout="fullWidth"
                   placeholder="blurred"
                   aspectRatio={2000 / 1141}
      />
    </Container>
  )
}

export default MadeInJapan