import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import MediaQueries from './media-queries'

const Section = styled.section`
  max-width: 1000px;
  margin: 4rem auto 0;
`

const Title = styled.h2`
  font-weight: 400;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  
  ${MediaQueries.greaterThan('tablet')`
    flex-direction: row; 
    padding: 0;
  `};
`

const Col = styled.div`
  flex-basis: 100%;
  min-width: 100%;
  max-width: 100%;
  
  ${MediaQueries.greaterThan('tablet')`
    flex-basis: 50%;
    min-width: 50%;
    max-width: 50%;
    padding: 1rem;
  `};
`

const Heading = styled.h3`
  margin: 1rem 0 0;
  font-weight: 400;
  
  ${MediaQueries.greaterThan('tablet')`
    margin: 0 
  `};
`

const title = '目に優しい。均一な光'
const heading = 'ムラの無い均一な光'
const content = '瞳は明るい場所と暗い場所で瞳孔が敏感に反応しています。その負担をなくすために、ムラのない光が必要でした。そのために開発したのが作業面を均一に照らす「北米照明学会基準 均斉度 １：３」で、かつてない製品を実現しました。'

const Feature01 = () => {
  return (
    <Section>
      <Title>{title}</Title>
      <Row>
        <Col>
          <StaticImage src="../images/feature-01.jpg"
                       alt="EXARM ZETA 目に優しい。均一な光"
                       formats={[ 'auto', 'webp', 'avif' ]}
                       layout="fullWidth"
                       aspectRatio={842 / 759}
                       placeholder="blurred"
          />
        </Col>
        <Col>
          <Heading>{heading}</Heading>
          <p>{content}</p>
          <StaticImage src="../images/feature-02.png"
                       alt="EXARM ZETA 北米照度学会の均斉度"
                       formats={[ 'auto', 'webp', 'avif' ]}
                       layout="fullWidth"
                       aspectRatio={843 / 790}
                       placeholder="blurred"
          />
        </Col>

      </Row>
    </Section>
  )
}

export default Feature01