import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import MediaQueries from './media-queries'

const Section = styled.section`
  margin-top: 6rem;
  ${MediaQueries.greaterThan('tablet')`
    margin-top: 10rem;
  `};
`

const Title = styled.h2`
  text-align: center;
  font-weight: 400;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 0;
  flex-direction: column;

  ${MediaQueries.greaterThan('tablet')`
    flex-direction: row; 
    max-width: 1200px;
    margin: 4rem auto;
  `};
`

const Col = styled.div`
  flex-basis: 100%;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  padding: 0 2rem;
  margin-top: 3rem;

  ${MediaQueries.greaterThan('tablet')`
    flex-basis: 33.3333%;
    min-width: 33.3333%;
    max-width: 33.3333%;
    padding: 0 1.5rem;
    margin-top: 0;
  `};

  ${MediaQueries.greaterThan('desktop')`
    padding: 0 2.5rem; 
  `};
`

const Label = styled.div`
  position: absolute;
  top: -20px;
  left: 10px;
  border-radius: 9999px;
  border: 1px solid #000;
  width: 80px;
  height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
  text-align: center;
  font-size: 0.675rem;

  ${MediaQueries.greaterThan('tablet')`
    width: 90px;
    height: 90px;
    font-size: 0.75rem;
    top: -40px;
    left: 5px;
  `};

  ${MediaQueries.greaterThan('desktop')`
    top: -20px;
    left: 10px; 
  `};
`

const Scenes = () => {
  return (
    <Section>
      <Title>活用シーン</Title>
      <Row>
        <Col>
          <StaticImage src="../images/exarm-zeta-scene-01.jpg"
                       alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                       layout="fullWidth"
                       aspectRatio={862 / 586}
                       placeholder="blurred"
          />
          <Label>ゲーム</Label>
        </Col>
        <Col>
          <StaticImage src="../images/exarm-zeta-scene-remote-work.jpg"
                       alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                       layout="fullWidth"
                       aspectRatio={862 / 586}
                       placeholder="blurred"
          />
          <Label>リモート<br />ワーク</Label>
        </Col>
        <Col>
          <StaticImage src="../images/exarm-zeta-scene-03.jpg"
                       alt="EXARM ZETA" formats={[ 'auto', 'webp', 'avif' ]}
                       layout="fullWidth"
                       aspectRatio={862 / 586}
                       placeholder="blurred"
          />
          <Label>クリエイター</Label>
        </Col>
      </Row>
    </Section>
  )
}

export default Scenes