import React from 'react'
import styled from 'styled-components'
import '@fontsource/lato/300.css'
import MediaQueries from './media-queries'

const Section = styled.section`
  background-color: #fff;
  color: #000;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  ${MediaQueries.greaterThan('desktop')`
    padding: 4rem 0;
    max-width: 800px;
  `};
`

const Title = styled.h2`
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  margin: 0;
`

const Content = styled.p`
  letter-spacing: 0.05rem;
  line-height: 2.0rem;
  margin-top: 3rem;
`

const Concept = () => {
  return (
    <Section>
      <Title>CONCEPT</Title>
      <Content>
        日本でもついに世界と同様にゲームが遊びからスポーツへと意識変化がはじまってくる時代になってきました。<br />
        元プロゲーマーの経験談から目に対する負担がある事がわかり、照明メーカーとして目に優しいライトを作りたいと思いました。また、日中は仕事をし夜はゲームをプレイする生活を送っている方が多く存在していることを知り、 フェイスライトや演出感を高めるイルミネーション機能を搭載し、ライフサイクルにフィットするようにしました。<br />
        昼でも夜でも活躍できるゲーミングライトに仕上がりました。ゲームを愛する方はもちろんのことクリエイター、フォトグラファー、デザイナーなど長時間モニターを見続ける全ての方々の必須アイテムとして EXARM ZETAが 活躍することを願っています。
      </Content>
    </Section>
  )
}

export default Concept