import React from 'react'
import styled from 'styled-components'
import MediaQueries from '../media-queries'
import { StaticImage } from 'gatsby-plugin-image'

const Section = styled.section`

`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  
  ${MediaQueries.greaterThan('tablet')`
    flex-direction: row
  `};
`

const Col = styled.div`
  &:first-child {
    flex-basis: 100%;
    min-width: 100%;
    max-width: 100%;
    
    ${MediaQueries.greaterThan('tablet')`
      flex-basis: 33%;
      min-width: 33%;
      max-width:33%;
    `};
  }
  
  &:last-child {
    flex-basis: 100%;
    min-width: 100%;
    max-width: 100%;
    
    ${MediaQueries.greaterThan('tablet')`
      padding-left: 2rem;
      flex-basis: 67%;
      min-width: 67%;
      max-width:67%;
    `};
  }
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 1.2rem;
  margin: 2rem 0 0;
  
  ${MediaQueries.greaterThan('tablet')`
    margin: 0;
  `};
`

const Content = styled.p`
  line-height: 1.8rem;
`

const title = 'ゲーム実況者 タコマン'
const content = 'とにかくカッコいいデザイン、 ゲーミングデバイスらしく7色に光るのに感動!!<br />モニターの上に重ねられる形状だから照明を固定しやすく、スペースを圧迫しないのが好印象。暗い場所でプレイの際にキーボードが見えにくかったが手元が明るく見えるので目に優しいと感じてます。さらに国産照明なので技術力に信頼があるから製品として安心。オススメできます!!'

const Voice01 = () => {
  return (
    <Section>
      <Row>
        <Col>
          <StaticImage src="../../images/thumb-voice-01.jpg"
                       alt="EXARM ZETA 目に優しい。均一な光"
                       formats={[ 'auto', 'webp', 'avif' ]}
                       layout="fullWidth"
                       aspectRatio={200 / 261}
                       placeholder="blurred"
          />
        </Col>
        <Col>
          <Title>{title}</Title>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </Col>

      </Row>
    </Section>
  )
}

export default Voice01