import React from 'react'
import styled from 'styled-components'
import Voice01 from './voices/voice01'
import Voice02 from './voices/voice02'
import Voice03 from './voices/voice03'
import MediaQueries from './media-queries'

const Section = styled.section`
  max-width: 1200px;
  margin: 4rem auto 0;
  padding: 0 2rem;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 4rem;
  margin-top: 3rem;
  
  ${MediaQueries.greaterThan('tablet')`
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `};
`

const Col = styled.div`
`

const Title = styled.h2`
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  margin: 0; 
`

const title = "USER'S VOICE"

const Voices = () => {
  return (
    <Section>
      <Title>{title}</Title>
      <Row>
        <Col>
          <Voice01 />
        </Col>
        <Col>
          <Voice02 />
        </Col>
        <Col>
          <Voice03 />
        </Col>
      </Row>
    </Section>
  )
}

export default Voices