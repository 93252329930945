import * as React from 'react'
import '../styles/global.css'
import '@fontsource/lato'
import '@fontsource/noto-sans-jp'
import styled from 'styled-components'
import Seo from '../components/seo'
import Header from '../components/header'
import Game from '../components/game'
import DeskWork from '../components/desk-work'
import Movie from '../components/movie'
import ConceptImages from '../components/concept-images'
import MadeInJapan from '../components/made-in-japan'
import Concept from '../components/concept'
import Story from '../components/story'
import Feature01 from '../components/feature-01'
import Voices from '../components/voices'
import Scenes from '../components/scenes'
import Makuake from '../components/makuake'
import Form from '../components/form'
import Footer from '../components/footer'
import CallToAction from '../components/call-to-action'
import { Helmet } from 'react-helmet'
import Store from '../components/store'
import Blog from '../components/blog'
import Official from '../components/official'

const Main = styled.main`
  color: #000;
  position: relative;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const IndexPage = () => {
  return (
    <div>
      <title>EXARM ZETA</title>
      <Helmet>
        <script>
          {`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PNPSQZX');
          `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-353926459"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'AW-353926459');
          `}
        </script>
        <script>
          {`
            function gtag_report_conversion(url) {
              var callback = function () {
                if (typeof(url) != 'undefined') {
                  window.location = url;
                }
              };
              gtag('event', 'conversion', {
                'send_to': 'AW-353926459/1xfiCNi4gL8CELv64agB',
                'event_callback': callback
              });
              return false;
            }
          `}
        </script>
      </Helmet>
      <Seo title="EXARM ZETA" pathname="/" image={{ src: '/thumb-exarm-zeta.jpg', width: 1280, height: 640 }} />
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PNPSQZX" height="0" width="0" style={{'display':'none','visibility':'hidden'}}></iframe></noscript>
      <Header />
      <Main>
        <Game />
        <DeskWork />
        <Movie />
        <ConceptImages />
        <MadeInJapan />
        <Concept />
        <Story />
        <Feature01 />
        <Voices />
        <Blog />
        <Scenes />
        <Store />
        <Official />
        <Makuake />
        <Form />
        {/*<CallToAction />*/}
      </Main>
      <Footer />
    </div>
  )
}

export default IndexPage
